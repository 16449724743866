<template>
  <div class="news2">
    <div class="container">
      <el-row :gutter="24">
        <el-col :span="18" style="margin-bottom: 56px">
          <div style="background-color: #fff">
            <div class="news2__title-container">
              <span>首页 > </span>
              <span>新闻资讯 > </span>
              <span>资讯详情</span>
            </div>
            <div class="news2__content-left">
              <div class="news2-title">SMT贴片检验有哪些标准</div>
              <div class="news2-createTime">
                <span>发布时间： 2021/1/19 13:38:31 </span>
                <span>浏览： 68次</span>
              </div>
              <div style="font-size: 16px;padding-bottom: 64px;border-bottom: 1px solid #d4d4d4">
                <p class="news2__p-1">SMT贴片检验这一步骤，可以规范SMT加工的工艺质量要求，以确保产品品质符合要求。下面一起来看看SMT贴片检验有哪些标准？</p>

                <div class="news2-subtitle">一、SMT贴片锡膏工艺</div>
                <p class="news2__p-2">1、PCB板上印刷的喷锡的位置与焊盘居中，无明显的偏移，不可影响SMT元器件的粘贴与上锡效果。</p>
                <p class="news2__p-3">2、PCB板上印刷喷锡量适中，不能完整的覆盖焊盘，少锡、漏刷。</p>
                <p class="news2__p-4">3、PCB板上印刷喷锡点成形不良，印刷喷锡连锡、喷锡成凹凸不平状，喷锡移位超焊盘三分之一。</p>

                <div class="news2-subtitle" style="margin-top: 20px">二、SMT贴片红胶工艺</div>
                <p style="line-height: 1.5">1、印刷红胶的位置居中，无明显的偏移，不可以影响粘贴与焊锡。</p>
                <p style="line-height: 1.5">2、印刷红胶胶量适中，能良好的粘贴，无欠胶。</p>
                <p style="line-height: 1.5">3、印刷红胶胶点偏移两焊盘中间，可能造成元件与焊盘不易上锡。</p>
                <p style="line-height: 1.5">4、印刷红胶量过多，从元件体侧下面渗出的胶的宽度大于元件体宽的二分之一。</p>

                <div class="news2-subtitle" style="margin-top: 20px">三、SMT贴片工艺</div>
                <p style="line-height: 1.5">1、SMT元器件贴装需整齐、正中，无偏移、歪斜。</p>
                <p style="line-height: 1.5">
                  2、SMT元器件贴装位置的元器件型号规格应正确，元器件应反面。元器件贴反（不允许元件有区别的相对称的两个面互换位置，如：有丝印标识的面与无丝印标识的面上下颠倒面），功能无法实现。</p>
                <p style="line-height: 1.5">3、有极性要求的贴片元器件贴装需按正确的极性标示加工。器件极性贴反、错误（二极管、三极管、钽质电容）。</p>
                <p style="line-height: 1.5">4、多引脚器件或相邻元件焊盘应无连锡、桥接短路。</p>
                <p style="line-height: 1.5">5、多引脚器件或相邻元件焊盘上应无残留的锡珠、锡渣。</p>
              </div>

              <div style="padding-top: 20px;padding-bottom: 40px">
                <router-link to="/news3">
                  <span style="color: #DA261D;font-size: 16px">< 上一篇</span>
                </router-link>
                <router-link to="/news1">
                  <span class="fr" style="color: #DA261D;font-size: 16px">下一篇 ></span>
                </router-link>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div style="background-color: #fff;padding: 24px">
            <div style="font-size: 20px;font-weight: bold">热点资讯</div>
            <div style="border-bottom: 1px solid #D4D4D4">
              <p style="margin-top: 18px;margin-bottom: 8px;font-size: 16px">SMT贴片检验有哪些标准</p>
              <p style="font-size: 12px;color: #A6A6A6;margin-bottom: 8px">2021-01-22 15:47</p>
            </div>
            <div style="border-bottom: 1px solid #D4D4D4;margin-top: 32px">
              <p style="margin-top: 18px;margin-bottom: 8px;font-size: 16px">SMT贴片检验SMT对于电子行……</p>
              <p style="font-size: 12px;color: #A6A6A6;margin-bottom: 8px">2021-01-22 15:47</p>
            </div>
            <div style="border-bottom: 1px solid #D4D4D4;margin-top: 32px">
              <p style="margin-top: 18px;margin-bottom: 8px;font-size: 16px">什么是PCBA代工代料，PCBA……</p>
              <p style="font-size: 12px;color: #A6A6A6;margin-bottom: 8px">2021-01-22 15:47</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "@/styles/common.scss";

.news2 {
  background: #F6F6F6;

  .container {
    margin-top: 24px;
    padding: 0 gap();
  }
}

.news2__title-container {
  font-size: 14px;
  padding-top: 16px;
  padding-left: 20px;
  padding-bottom: 20px;
  background-color: #fff;

  span:nth-of-type(3) {
    color: #a6a6a6;
  }
}

.news2__content-left {
  margin-left: 20px;
  margin-right: 20px;
  background-color: #fff;

  .news2-title {
    font-size: 24px;
    font-weight: bold;
  }

  .news2-createTime {
    padding: 10px 0;
    font-size: 14px;
    color: #A6A6A6;
    border-bottom: 1px solid #D4D4D4;

    span:first-of-type {
      margin-right: 32px;
    }
  }

  .news2__p-1 {
    margin: 20px 0;

    line-height: 1.5;
  }

  .news2__p-2,
  .news2__p-3,
  .news2__p-4 {

    line-height: 1.5;
  }

  .news2-subtitle {
    font-weight: bold;

    margin-bottom: 10px;
  }
}
</style>
